import '@/src/styles/index.scss';
import type { AppProps } from 'next/app';
import { DM_Sans, Inter } from 'next/font/google';

import { HeaderContextProvider } from '../components/providers/headerContextProvider/HeaderContextProvider';
import { ModalProvider } from '../components/providers/modalContext/ModalContext';

const dmSans = DM_Sans({
    subsets: ['latin'],
    weight: ['400', '500', '600', '700'],
    variable: '--font-inter',
    display: 'swap',
});

export default function App({ Component, pageProps }: AppProps) {
    return (
        <>
            <style jsx global>
                {`
                    html {
                        font-family: ${dmSans.style.fontFamily};
                    }
                `}
            </style>
            <ModalProvider>
                <HeaderContextProvider>
                    <Component {...pageProps} />
                </HeaderContextProvider>
            </ModalProvider>
        </>
    );
}
