import React, { ReactNode, createContext, useContext, useState } from 'react';

type ModalContextType = {
    isModalOpen: boolean;
    setIsModalOpen: (isOpened: boolean) => void;
    handleOpenModal: () => void;
    handleCloseModal: () => void;
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

type ModalProviderProps = {
    children: ReactNode;
};

export function ModalProvider({ children }: ModalProviderProps) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setTimeout(() => setIsModalOpen(false), 100);
    };

    const modalContextValue: ModalContextType = {
        isModalOpen,
        setIsModalOpen,
        handleOpenModal,
        handleCloseModal,
    };

    return <ModalContext.Provider value={modalContextValue}>{children}</ModalContext.Provider>;
}

export function useModal() {
    const context = useContext(ModalContext);

    if (!context) {
        throw new Error('useModal must be used within a ModalProvider');
    }

    return context;
}
