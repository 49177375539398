import { useRouter } from 'next/router';
import { Dispatch, FC, ReactNode, SetStateAction, createContext, useEffect, useState } from 'react';

type PropsContextType = {
    isHeaderDark: boolean;
    setIsBundleScrolled: Dispatch<SetStateAction<boolean>>;
};

const initialContext = {
    isHeaderDark: false,
    setIsBundleScrolled: () => {},
};

export const HeaderContext = createContext<PropsContextType>(initialContext);

export const HeaderContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const path = useRouter();
    const [isHeaderDark, setIsHeaderDark] = useState(
        path.pathname.includes('bundle') ? true : false
    );
    const [isBundleScrolled, setIsBundleScrolled] = useState(false);

    useEffect(() => {
        if (path.pathname.includes('bundle')) {
            if (!isBundleScrolled) {
                setIsHeaderDark(true);
            } else {
                setIsHeaderDark(false);
            }
        } else {
            setIsHeaderDark(false);
        }
    }, [isBundleScrolled, path.pathname]);

    return (
        <>
            <HeaderContext.Provider
                value={{
                    isHeaderDark,
                    setIsBundleScrolled,
                }}>
                {children}
            </HeaderContext.Provider>
        </>
    );
};
